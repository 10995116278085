<template>
  <div>
    <!-- 부서 상세 -->
    <c-card title="LBL0000519" class="cardClassDetailForm no-margin">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- LBLSELECT: 선택 -->
          <c-btn 
            v-if="editable" 
            label="LBLSELECT" 
            icon="check"
            @btnClicked="select" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-12 dept-multi-area">
          <!-- LBL0000513: 부서 정보가 없습니다. -->
          <q-tree
            ref="dept-multi"
            :nodes="deptTree"
            tick-strategy="strict"
            node-key="deptCd"
            label-key="deptName"
            children-key="children"
            :no-nodes-label="$comm.getLangLabel('LBL0000513')"
            :default-expand-all="true"
            :ticked.sync="checkDepts"
          />
        </div>
      </template>
    </c-card>
  </div>
</template>

<script>
import arrayToTree from 'array-to-tree';
export default {
  name: 'dept-multi-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          plantCd: '',
          parentCheckDepts: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    },
    plantCd: {
      type: String,
    },
  },
  data() {
    return {
      checkDepts: [],
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isAdmin() {
      return Boolean(this.$store.getters.user.userId === 'U000000001')
    },
    depts() {
      let list = []; // this.$store.state.systemInfo.depts;
      if (this.$store.getters.user.plantCds !== null && this.$store.getters.user.plantCds !== '') {
        let plantCds = this.$store.getters.user.plantCds.split(',');
        this.$_.forEach(this.$store.state.systemInfo.depts, _item => {
          let index = plantCds.findIndex(a => a === _item.plantCd);
          if (index > -1) {
            list.push(_item);
          }
        })
      } else if (this.popupParam.plantCd) {
        list = this.$_.filter(this.$store.state.systemInfo.depts, { plantCd: this.popupParam.plantCd });
      }
      if (this.editable && !this.disabled) {
        // 사용여부가 Y인 것만 리스트에 표현한다.
        // default : 사용여부 상관없이 전체
        list = this.$_.filter(list, { useFlag: 'Y' });
      }
      if (!this.isAdmin) {
        list = this.$_.filter(list, item => {return item['systemFlag'] !== 'Y'});
      }
      return list;
    },
    deptTree() {
      let val = this.convertTree(this.depts);
      return val ? val : [];
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      if (this.popupParam.parentCheckDepts) {
        this.checkDepts = this.popupParam.parentCheckDepts.split(',');
      }
    },
    convertTree(_treeBases) {
      if (!_treeBases || _treeBases.length <= 0) return null;
      var tree = arrayToTree(_treeBases, {
        parentProperty: 'upDeptCd',
        customID: 'deptCd',
      });
      return tree;
    },
    select() {
      if (!this.checkDepts || this.checkDepts.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0000205', // 부서를 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', this.$refs['dept-multi'].getTickedNodes());
      }
    },
  }
};
</script>
<style scoped>
.dept-multi-area {
  height: 600px;
}
</style>